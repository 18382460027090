import React, { Component } from "react";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import LayoutDefault from "../../layout/layout-default.component";

import FilterSelectCategory from "../../layout/filter-select-category.component";
import FilterSelectBrand from "../../layout/filter-select-brand.component";
import FilterSelectClass from "../../layout/filter-select-class.component";

import SelectBrand from "../../layout/select-brand.component";
import SelectCategory from "../../layout/select-category.component";
import SelectClass from "../../layout/select-class.component";
import SelectFuel from "../../layout/select-fuel.component";
import SelectFuelSupply from "../../layout/select-fuel-supply-system.component";
import SelectTransmission from "../../layout/select-transmission.component";
import SelectActive from "../../layout/select-active.component";
import SelectDriveType from "../../layout/select-drive-type.component";
import SelectFeedbackType from "../../layout/select-feedback-type.component";
import SelectFeedbackLanguage from "../../layout/select-feedback-language.component";
import SelectDisplayType from "../../layout/select-display-type.component";
import SelectDisplayCategory from "../../layout/select-display-category.component";
import SelectChairs from "../../layout/select-chairs.component";
import SelectDistributionDestination from "../../layout/select-destination-distribution";
import EditorAbout from "../../layout/editor-about.component";

import { vehicleServices } from "../../../services/vehicle.services";
import { vehicleServicesPost } from "../../../services/vehicle-post.services";
import { modalError, modalSuccess } from "../../../helpers/modal.helper";

import { Modal, Form, Input, Table, Button, Select, Image } from "antd";
import { SearchOutlined, PlusSquareOutlined, DeleteOutlined, CloudUploadOutlined, EditOutlined } from "@ant-design/icons";
import SelectSegment from "../../layout/select-segment.component";
import { distributionServices } from "../../../services/distribution.services";

const { TextArea } = Input;
class MasterVehicleModel extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.initState = {
      processing: false,
      processingFetchTableData: false,
      pageSize: 100,

      tableSelectedRowKeys: [],
      titleCanvas: "Default Title",

      inputModelUUID: "",
      inputValueModelName: "",
      inputValueBrandUUID: "",
      inputValueBrandName: "",
      inputValueCategory: "",
      inputValueClass: "",
      inputValueMarketName: "",
      inputValueSeriesName: "",
      inputValueEngineCapacity: "",
      inputValueEngineCapacityMinimum: "",
      inputValueEngineCapacityMaximum: "",
      inputValueFuel: [],
      inputValueFuelSupplySystem: [],
      inputValueTransmission: [],
      inputValueYearProduction: "",
      inputValueYearProductionFirst: "",
      inputValueYearProductionLastest: "",
      inputValueUrlInfo: "",
      inputValueUrlImage: "",
      inputValueUrlImageThumb: "",
      inputValueSegment: "",
      inputValueDriveType: "",
      inputValueDoors: "",
      inputValueChairs: [],
      inputValueStatus: "",
      inputValueAbout: "",

      feedback_list: [],
      feedback_modal: false,
      inputFeedbackAuthor: "",
      inputFeedbackTitle: "",
      inputFeedbackType: "",
      inputFeedbackLanguage: "",
      inputFeedbackDescription: "",

      display_list: [],
      display_modal: false,

      inputDisplayCategory: "",
      inputDisplayType: "",
      inputDisplayLink: "",
      inputDisplayLinkThumb: "",
      inputDisplayCaption: "",

      loadingButton: false,
      showHideReadCanvas: "d-block",
      showHideFormCanvas: "m-2 px-1 d-none",
      showHideButtonForm: "me-1",
      formEdit: false,

      distributeModal: false,
      distributeModel: {},
      distModalLabel: "",
      distDestination: "NONE",
      dataOptionArea: [],
      distArea: "",
      distPriceDefault: 0,
      distPriceStrikethrough: 0,
      distPriceMin: 0,
      distPriceMax: 0,
      distImageUrl: "",
      distImageThumbUrl: "",
    };

    this.state = { ...this.initState };
    this.formInput = React.createRef();
  }

  tableColumn = [
    {
      title: "Model UUID",
      dataIndex: "model_uuid",
      key: "model_uuid",
    },
    {
      title: "Model Name",
      dataIndex: "model_name",
      key: "model_name",
    },
    {
      title: "Brand UUID",
      dataIndex: "brand_uuid",
      key: "brand_uuid",
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
    },
    {
      title: "Market Name",
      dataIndex: "market_name",
      key: "market_name",
    },
    {
      title: "Segment",
      dataIndex: "segment",
      key: "segment",
    },
    {
      title: "Action",
      dataIndex: "model_uuid",
      key: "model_uuid",
      render: (text, record) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={() => {
                this.showDistributeModal(record);
              }}
              icon={<CloudUploadOutlined />}
              type="primary"
              title="Distribute"
              style={{
                background: "#36383a",
                borderColor: "#36383a",
                minWidth: "40px",
              }}
              loading={this.state.loadingButton}
            />
            <Button
              onClick={() => {
                this.onClickEditModel(record);
              }}
              icon={<EditOutlined />}
              type="primary"
              title="Edit"
              style={{
                background: "#ad253e",
                borderColor: "#ad253e",
                minWidth: "40px",
              }}
              loading={this.state.loadingButton}
            />
          </div>
        );
      },
    },
    {
      title: "Preview",
      dataIndex: "url_image_thumbnail",
      key: "url_image_thumbnail",
      render: (text, record) => {
        // console.log("url_image_thumbnail", record.url_image_thumbnail);
        // console.log("url_image", record.url_image);

        return (
          <>
            {!record.url_image_thumbnail && !record.url_image ? (
              <span>No Image</span> // Display "NONE" if both URLs are empty
            ) : (
              <Image
                src={!record.url_image_thumbnail ? record.url_image : record.url_image_thumbnail}
                preview={true} // Enable image preview
                style={{
                  width: "30px", // Adjust image size
                  height: "30px",
                  display: "block",
                }}
                alt="Show Image"
              />
            )}
          </>
        );
      },
    },
  ];

  changeFilterCategory = (v) => {
    this.setState({
      filterValueCategory: v,
    });
  };

  changeFilterClass = (v) => {
    this.setState({
      filterValueClass: v,
    });
  };

  changeFilterBrand = (v) => {
    // console.log(v);
    this.setState({
      filterValueBrand: v,
    });
  };

  fetchDataModel = async () => {
    try {
      const filter = this.state;

      const params = {};

      if (filter.filterValueModelUUID !== "") {
        params.model_uuid = filter.filterValueModelUUID;
      }
      // console.log(filter.filterValueModelUUID);

      if (filter.filterValueCategory !== "" && filter.filterValueCategory !== "0" && filter.filterValueBrand !== "" && filter.filterValueBrand !== "0") {
        params.category = filter.filterValueCategory;
        params.brand_uuid = filter.filterValueBrand;
      }

      if (filter.filterValueClass !== "" && filter.filterValueClass !== "0") {
        params.class = filter.filterValueClass;
      }

      if (Object.keys(params).length > 0) {
        this.setState({
          processingFetchTableData: true,
          loadingButton: true,
        });

        const dataModel = await vehicleServices.getModelList(params);

        this.setState({
          dataModel: dataModel.data.data,
          processingFetchTableData: false,
          loadingButton: false,
          tableSelectedRowKeys: [],
        });
      } else {
        modalError({
          title: "Validation",
          content: "Invalid data filter",
        });
      }
    } catch (error) {
      this.setState({
        dataModel: [],
        processingFetchTableData: false,
        loadingButton: false,
        tableSelectedRowKeys: [],
      });
    }
  };

  onClickFilter = async () => {
    await this.setState({
      filterValueModelUUID: "",
    });
    await this.fetchDataModel();
  };

  onClickAddModel = async () => {
    this.setState({
      inputModelUUID: "",
      titleCanvas: "Add New Model",
      showHideReadCanvas: "d-none",
      showHideFormCanvas: "m-2 px-1 d-block",
      formEdit: false,
    });
  };

  onClickEditModel = async (r) => {
    if (r?.feedbacks?.data !== null && r?.feedbacks?.data?.length > 0) {
      await this.setState({
        feedback_list: r?.feedbacks?.data,
      });
    }
    const list_fuel = [];
    for (const key in r.fuel) {
      if (Object.hasOwnProperty.call(r.fuel, key)) {
        list_fuel.push(key);
      }
    }

    const list_fuel_system = [];
    for (const key in r.fuel_supply_system) {
      if (Object.hasOwnProperty.call(r.fuel_supply_system, key)) {
        list_fuel_system.push(key);
      }
    }

    const list_transmission = [];
    for (const key in r.transmission) {
      if (Object.hasOwnProperty.call(r.transmission, key)) {
        list_transmission.push(key);
      }
    }

    if (r.url_display_assets !== null && r.url_display_assets?.length > 0) {
      await this.setState({
        display_list: r.url_display_assets,
      });
    }

    await this.setState({
      titleCanvas: "Detail Info",
      showHideReadCanvas: "d-none",
      showHideFormCanvas: "m-2 px-1 d-block",

      inputValueBrandUUID: r.brand_uuid,
      inputValueBrandName: r.brand_name,
      inputValueCategory: r.category,
      inputValueClass: r.class,

      inputValueFuel: list_fuel,
      inputValueFuelSupplySystem: list_fuel_system,
      inputValueTransmission: list_transmission,
      inputValueSegment: r.segment,

      inputModelUUID: r.model_uuid,
      inputValueModelName: r.model_name,
      inputValueMarketName: r.market_name,
      inputValueSeriesName: r.series_name,
      inputValueEngineCapacity: r.engine_capacity,
      inputValueEngineCapacityMinimum: r.engine_capacity_minimum,
      inputValueEngineCapacityMaximum: r.engine_capacity_maximum,
      inputValueYearProduction: r.production_year,
      inputValueYearProductionFirst: r.production_year,
      inputValueYearProductionLastest: r.production_year,
      inputValueUrlInfo: r.url_info,
      inputValueUrlImage: r.url_image,
      inputValueUrlImageThumb: r.url_image_thumbnail,

      inputValueDriveType: r.drive_type,
      inputValueDoors: r.doors,
      inputValueChairs: r.chairs,
      inputValueStatus: r?.active?.toString()?.toLowerCase(),
      inputValueAbout: r.about,
      inputFeedbackAuthor: r?.feedbacks?.author,

      showHideButtonForm: "d-none",
      formEdit: true,
    });

    this.formInput.current.setFieldsValue({
      brand_uuid: r.brand_uuid,
      category: r.category,
      class: r.class,
      fuel: list_fuel,
      fuel_supply_system: list_fuel_system,
      transmission: list_transmission,
      segment: r.segment,
      drive_type: r.drive_type,
      status: r?.active?.toString()?.toLowerCase(),
    });
  };

  onClickCancel = async () => {
    const initState = { ...this.initState };

    this.setState(initState);
    this.formInput.current.resetFields();
  };

  changeInputValueModelName = (v) => {
    this.setState({
      inputValueModelName: v.target.value,
    });
  };

  changeInputValueBrand = (v, i) => {
    this.setState({
      inputValueBrandUUID: v,
      inputValueBrandName: i.children,
    });
  };

  changeInputCategory = (v) => {
    this.setState({
      inputValueCategory: v,
    });
  };

  changeInputClass = (v) => {
    this.setState({
      inputValueClass: v,
    });
  };

  changeInputValueMarketName = (v) => {
    this.setState({
      inputValueMarketName: v.target.value,
    });
  };

  changeInputValueFuel = (v) => {
    this.setState({
      inputValueFuel: v,
    });
  };

  changeInputValueFuelSupplySystem = (v) => {
    this.setState({
      inputValueFuelSupplySystem: v,
    });
  };

  changeInputValueTransmission = (v) => {
    this.setState({
      inputValueTransmission: v,
    });
  };

  changeInputValueSegment = (v) => {
    this.setState({
      inputValueSegment: v,
    });
  };

  changeInputValueSeriesName = (v) => {
    this.setState({
      inputValueSeriesName: v.target.value,
    });
  };

  changeInputValueEngineCapacity = (v) => {
    this.setState({
      inputValueEngineCapacity: v.target.value,
    });
  };

  changeInputValueEngineCapacityMinimum = (v) => {
    this.setState({
      inputValueEngineCapacityMinimum: v.target.value,
    });
  };

  changeInputValueEngineCapacityMaximum = (v) => {
    this.setState({
      inputValueEngineCapacityMaximum: v.target.value,
    });
  };

  changeInputValueChairs = (v) => {
    // console.log("changeInputValueChairs", v);
    this.setState({
      inputValueChairs: v,
    });
  };

  changeInputValueYearProduction = (v) => {
    this.setState({
      inputValueYearProduction: v.target.value,
    });
  };

  changeInputValueYearProductionFirst = (v) => {
    this.setState({
      inputValueYearProductionFirst: v.target.value,
    });
  };

  changeInputValueYearProductionLastest = (v) => {
    this.setState({
      inputValueYearProductionLastest: v.target.value,
    });
  };

  changeInputUrlInfo = (v) => {
    this.setState({
      inputValueUrlInfo: v.target.value,
    });
  };

  changeInputUrlImage = (v) => {
    this.setState({
      inputValueUrlImage: v.target.value,
    });
  };

  changeInputUrlImageThumb = (v) => {
    this.setState({
      inputValueUrlImageThumb: v.target.value,
    });
  };

  changeInputValueDoors = (v) => {
    this.setState({
      inputValueDoors: v.target.value,
    });
  };

  changeInputValueAbout = (html) => {
    this.setState({
      inputValueAbout: html,
    });
  };

  changeInputValueDriveType = (v) => {
    this.setState({
      inputValueDriveType: v,
    });
  };

  changeInputValueStatus = (v) => {
    this.setState({
      inputValueStatus: v,
    });
  };

  changeInputFeedbackAuthor = (v) => {
    this.setState({
      inputFeedbackAuthor: v.target.value,
    });
  };

  changeInputFeedbackTitle = (v) => {
    this.setState({
      inputFeedbackTitle: v.target.value,
    });
  };

  changeInputFeedbackType = (v) => {
    this.setState({
      inputFeedbackType: v,
    });
  };

  changeInputFeedbackLanguage = (v) => {
    this.setState({
      inputFeedbackLanguage: v,
    });
  };

  changeInputFeedbackDescription = (v) => {
    this.setState({
      inputFeedbackDescription: v.target.value,
    });
  };

  showFeedbackModal = async () => {
    this.setState({
      inputFeedbackTitle: "",
      inputFeedbackType: "",
      inputFeedbackLanguage: "",
      inputFeedbackDescription: "",
      feedback_modal: true,
    });
  };

  closeFeedbackModal = async () => {
    this.setState({
      inputFeedbackTitle: "",
      inputFeedbackType: "",
      inputFeedbackLanguage: "",
      inputFeedbackDescription: "",
      feedback_modal: false,
    });
  };

  addFeedbackList = async () => {
    const feedback_title = this.state.inputFeedbackTitle;
    const feedback_type = this.state.inputFeedbackType;
    const feedback_language = this.state.inputFeedbackLanguage;
    const feedback_description = this.state.inputFeedbackDescription;

    if (feedback_title !== "" && feedback_type !== "" && feedback_language !== "") {
      const feedback_list = this.state.feedback_list;
      const new_display = {
        title: feedback_title,
        type: feedback_type,
        language: feedback_language,
        description: feedback_description,
      };

      feedback_list.push(new_display);
      this.setState({
        feedback_modal: false,
        display_list: feedback_list,
      });

      this.closeFeedbackModal();
    } else {
      modalError({
        title: "Error Validation",
        content: "Error Validation",
      });
    }
  };

  deleteFeedbackList = (value) => {
    let feedback_list = this.state.feedback_list;
    const index = feedback_list.indexOf(value);
    if (index !== -1) {
      feedback_list.splice(index, 1);
    }
    this.setState({
      feedback_list: feedback_list,
    });
  };

  changeInputDisplayType = (v) => {
    this.setState({
      inputDisplayType: v,
    });
  };

  changeInputDisplayCategory = (v) => {
    this.setState({
      inputDisplayCategory: v,
    });
  };

  changeInputDisplayLink = (v) => {
    this.setState({
      inputDisplayLink: v.target.value,
    });
  };

  changeInputDisplayLinkThumb = (v) => {
    this.setState({
      inputDisplayLinkThumb: v.target.value,
    });
  };

  changeInputDisplayCaption = (v) => {
    this.setState({
      inputDisplayCaption: v.target.value,
    });
  };

  showDisplayModal = async () => {
    this.setState({
      inputDisplayType: "",
      inputDisplayCategory: "",
      inputDisplayCaption: "",
      inputDisplayLink: "",
      inputDisplayLinkThumb: "",
      display_modal: true,
    });
  };

  closeDisplayModal = async () => {
    this.setState({
      inputDisplayType: "",
      inputDisplayCategory: "",
      inputDisplayCaption: "",
      inputDisplayLink: "",
      inputDisplayLinkThumb: "",
      display_modal: false,
    });
  };

  addDisplayList = async () => {
    const display_type = this.state.inputDisplayType;
    const display_category = this.state.inputDisplayCategory;
    const display_caption = this.state.inputDisplayCaption;
    const display_link = this.state.inputDisplayLink;
    const display_link_thumb = this.state.inputDisplayLinkThumb;

    if (display_type !== "" && display_category !== "" && display_caption !== "" && display_link !== "" && display_link_thumb !== "") {
      const display_list = this.state.display_list;
      const new_display = {
        type: display_type,
        category: display_category,
        caption: display_caption,
        url_asset: display_link,
        url_asset_thumbnail: display_link_thumb,
        show: true,
      };

      display_list.push(new_display);
      this.setState({
        display_modal: false,
        display_list: display_list,
      });

      this.closeDisplayModal();
    } else {
      modalError({
        title: "Error Validation",
        content: "Error Validation",
      });
    }
  };

  deleteDisplayList = (value) => {
    let display_list = this.state.display_list;
    const index = display_list.indexOf(value);
    if (index !== -1) {
      display_list.splice(index, 1);
    }
    this.setState({
      display_list: display_list,
    });
  };

  showDistributeModal = async (row) => {
    if (row) {
      let minPrice = 0;
      let maxPrice = 0;

      const dataVariant = await vehicleServices.getVariantlList({
        brand_uuid: row.brand_uuid,
        model_uuid: row.model_uuid,
      });

      if (dataVariant?.data?.data?.length > 0) {
        const sortedByPrice = dataVariant?.data?.data.sort((a, b) => a.price - b.price);

        minPrice = sortedByPrice[0].price; // Harga terendah
        maxPrice = sortedByPrice[sortedByPrice.length - 1].price; // Harga tertinggi
      }

      this.setState({
        distributeModal: true,
        distributeModel: row,
        distModalLabel: row?.model_name,
        distDestination: "NONE",
        dataOptionArea: [],
        distArea: "",
        distPriceDefault: minPrice,
        distPriceStrikethrough: 0,
        distPriceMin: minPrice,
        distPriceMax: maxPrice,
        distImageUrl: row.url_image || "https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png",
        distImageThumbUrl: row.url_image_thumbnail || "https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png",
      });
    }
  };

  closeDistributeModal = async () => {
    this.setState({
      distributeModal: false,
      distributeModel: {},
      distModalLabel: "",
      distDestination: "NONE",
      dataOptionArea: [],
      distArea: "",
      distPriceDefault: 0,
      distPriceStrikethrough: 0,
      distPriceMin: 0,
      distPriceMax: 0,
      distImageUrl: "",
      distImageThumbUrl: "",
    });
  };

  changeDistDestination = async (v) => {
    await this.setState({
      distDestination: v,
    });
    if (v !== "NONE") {
      await this.fetchDataArea();
    }
  };

  fetchDataArea = async () => {
    try {
      const destination = this.state.distDestination;

      if (destination !== "NONE" && destination !== "") {
        const dataModel = await distributionServices.getArea(destination);

        this.setState({
          dataOptionArea: dataModel.data.data,
        });
      }
    } catch (error) {
      this.setState({
        dataOptionArea: [],
      });
    }
  };

  onDistributeData = async () => {
    try {
      const { distributeModel, distDestination, distArea, distPriceDefault, distPriceStrikethrough, distPriceMin, distPriceMax, distImageUrl, distImageThumbUrl } = this.state;

      if (Object.keys(distributeModel).length < 1) {
        throw new Error("Error Validation, No Model Selected");
      }

      if (!distDestination || distDestination === "NONE") {
        throw new Error("Error Validation, Required Distribute Destination");
      }
      if (!distArea) {
        throw new Error("Error Validation, Required Distribute Area");
      }
      if (!distPriceDefault || distPriceDefault < 0) {
        throw new Error("Error Validation, Required Distribute Price Default");
      }
      if (distPriceStrikethrough < 0) {
        throw new Error("Error Validation, Required Distribute Price Strikethrough");
      }
      if (!distPriceMin || distPriceMin < 0) {
        throw new Error("Error Validation, Required Distribute Price Min");
      }
      if (!distPriceMax || distPriceMax < 0) {
        throw new Error("Error Validation, Required Distribute Price Max");
      }
      if (!distImageUrl || distImageUrl === "NONE") {
        throw new Error("Error Validation, Required Distribute Image Url");
      }
      if (!distImageThumbUrl || distImageThumbUrl === "NONE") {
        throw new Error("Error Validation, Required Distribute Image ThumbUrl");
      }

      const data_post = {
        company_code: distDestination,
        area_code: distArea,
        brand_uuid: distributeModel.brand_uuid,
        brand: distributeModel.brand_name,
        class: distributeModel.class,
        category: distributeModel.category,
        model_uuid: distributeModel.model_uuid,
        name: distributeModel.market_name,
        price: Number(distPriceDefault),
        price_strikethrough: Number(distPriceStrikethrough),
        price_max: Number(distPriceMin),
        price_min: Number(distPriceMax),
        sorting: 0,
        label: "new",
        url_image: distImageUrl,
        url_thumb_image: distImageThumbUrl,
        url_brochure: "",
        url_video: "",
        url_specification: "",
        highlight: true,
        active: true,
        fuel: distributeModel.fuel,
        seat: distributeModel.chairs,
        transmission: distributeModel.transmission,
        engine_capacity: Number(distributeModel.engine_capacity),
        engine_capacity_min: Number(distributeModel?.engine_capacity_minimum) || Number(distributeModel.engine_capacity),
        engine_capacity_max: Number(distributeModel?.engine_capacity_maximum) || Number(distributeModel.engine_capacity),
      };

      await distributionServices.addModel(data_post);
      // console.log("distributed", distributed?.data);

      Modal.success({
        content: "Success Distribute Data",
      });

      this.closeDistributeModal();
    } catch (error) {
      Modal.error({
        title: "Error Distribute Data",
        content: error?.message,
      });
    }
  };

  changeDistArea = (v) => {
    this.setState({
      distArea: v,
    });
  };

  onClickSave = async () => {
    const postData = {
      name: this.state.inputValueModelName.toString().toLowerCase(),
      market_name: this.state.inputValueMarketName,
      series_name: this.state.inputValueSeriesName,
      category: this.state.inputValueCategory,
      class: this.state.inputValueClass,
      brand_uuid: this.state.inputValueBrandUUID,
      brand_name: this.state.inputValueBrandName,

      engine_capacity: this.state.inputValueEngineCapacity,
      engine_capacity_minimum: this.state.inputValueEngineCapacityMinimum,
      engine_capacity_maximum: this.state.inputValueEngineCapacityMaximum,

      fuel: {},
      fuel_supply_system: {},
      transmission: {},
      segment: this.state.inputValueSegment,

      year_production: this.state.inputValueYearProduction,
      year_first_production: this.state.inputValueYearProductionFirst,
      year_latest_production: this.state.inputValueYearProductionLastest,
      url_image: this.state.inputValueUrlImage,
      url_image_thumbnail: this.state.inputValueUrlImageThumb,
      url_info: this.state.inputValueUrlInfo,

      drive_type: this.state.inputValueDriveType,
      doors: this.state.inputValueDoors,
      chairs: this.state.inputValueChairs,
      active: this.state.inputValueStatus === "true" ? true : false,
      about: this.state.inputValueAbout,
      feedbacks: {
        author: this.state.inputFeedbackAuthor,
        data: this.state.feedback_list,
      },
      url_display_assets: this.state.display_list,
    };
    console.log(this.state.inputValueChairs);
    console.log("chairs", postData.chairs);
    // return;

    if (!postData.name) {
      modalError({
        title: "Validation Error",
        content: "Invalid Model Name",
      });
      return false;
    }
    if (!postData.market_name) {
      modalError({
        title: "Validation Error",
        content: "Invalid Market Name",
      });
      return false;
    }
    if (!postData.series_name) {
      modalError({
        title: "Validation Error",
        content: "Invalid Series Name",
      });
      return false;
    }
    if (!postData.category) {
      modalError({
        title: "Validation Error",
        content: "Invalid Category",
      });
      return false;
    }
    if (!postData.class) {
      modalError({
        title: "Validation Error",
        content: "Invalid Class",
      });
      return false;
    }
    if (!postData.brand_uuid) {
      modalError({
        title: "Validation Error",
        content: "Invalid Brand",
      });
      return false;
    }

    if (!postData.segment) {
      modalError({
        title: "Validation Error",
        content: "Invalid Segment",
      });
      return false;
    }

    this.setState({
      loadingButton: true,
    });

    if (this.state.inputValueFuel.length > 0) {
      for (let i = 0; i < this.state.inputValueFuel.length; i++) {
        const el = this.state.inputValueFuel[i];
        postData.fuel[el] = true;
      }
    }

    if (this.state.inputValueFuelSupplySystem.length > 0) {
      for (let i = 0; i < this.state.inputValueFuelSupplySystem.length; i++) {
        const el = this.state.inputValueFuelSupplySystem[i];
        postData.fuel_supply_system[el] = true;
      }
    }

    if (this.state.inputValueTransmission.length > 0) {
      for (let i = 0; i < this.state.inputValueTransmission.length; i++) {
        const el = this.state.inputValueTransmission[i];
        postData.transmission[el] = true;
      }
    }

    // console.log(postData);

    try {
      if (this.state.formEdit) {
        postData.uuid = this.state.inputModelUUID;
        const token = this.cookies.get("_t_autotrimitrabiz");
        await vehicleServicesPost.setToken(token);
        await vehicleServicesPost.updateModel([postData]);
        this.onClickCancel();
        modalSuccess({
          content: "SUCCESS",
        });
      } else {
        postData.uuid = uuidv4();
        const token = this.cookies.get("_t_autotrimitrabiz");
        await vehicleServicesPost.setToken(token);
        await vehicleServicesPost.addModel([postData]);
        this.onClickCancel();
        modalSuccess({
          content: "SUCCESS",
        });
      }
      this.setState({
        filterValueModelUUID: postData.uuid,
      });
      await this.fetchDataModel();
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      modalError({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  handleFieldChange = (field, value) => {
    this.setState({ [field]: value });
  };

  render() {
    const rowTableSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          tableSelectedRowKeys: selectedRows,
        });
      },
    };

    return (
      <React.Fragment>
        <LayoutDefault defaultOpenKeys={["sm-master"]} defaultSelectedKeys={["m-master-vehicle-model"]}>
          <div id="read_data_canvas" className={this.state.showHideReadCanvas}>
            <Form name="form-filter" layout="vertical" className="pt-2">
              <div className="row mx-0">
                <div className="col-lg-10">
                  <div className="row px-2">
                    <div className="col-lg-2 px-1">
                      <FilterSelectCategory value={this.state.filterValueCategory} onChangeValue={this.changeFilterCategory} />
                    </div>
                    <div className="col-lg-2 px-1">
                      <FilterSelectBrand value={this.state.filterValueBrand} onChangeValue={this.changeFilterBrand} />
                    </div>
                    <div className="col-lg-2 px-1">
                      <FilterSelectClass value={this.state.filterValueClass} onChangeValue={this.changeFilterClass} />
                    </div>
                    <div className="col-lg-2 px-1">
                      <Form.Item label=" ">
                        <Button
                          onClick={this.onClickFilter}
                          type="primary"
                          icon={<SearchOutlined />}
                          title="filter"
                          className="custom-class px-3 mx-1 mb-1"
                          style={{
                            background: "#36383a",
                            borderColor: "#36383a",
                            minWidth: "40px",
                          }}
                          loading={this.state.loadingButton}
                        >
                          Filter
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 mb-1 me-0">
                  <Form.Item className="">
                    <Button onClick={this.onClickAddModel} title="add" type="primary" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton} className="custom-class float-end">
                      <span>
                        <strong style={{ fontSize: "100%" }}>+&nbsp;</strong> Add Model
                      </span>
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>

            <Table
              rowSelection={rowTableSelection}
              rowKey={"model_uuid"}
              size={"small"}
              loading={this.state.processingFetchTableData}
              columns={this.tableColumn}
              dataSource={this.state.dataModel}
              pagination={{ pageSize: this.state.pageSize }}
              onChange={this.onChangeTable}
            />
          </div>
          <div id="add_edit_canvas" className={this.state.showHideFormCanvas}>
            <Form ref={this.formInput} layout="vertical" labelCol={{ span: 12 }} wrapperCol={{ span: 24 }}>
              <div className="row mx-0">
                <div className="code-box-title  border-bottom border-secondary my-2 pb-2">
                  <strong>{this.state.titleCanvas}</strong>
                </div>
                <div className="col-lg-6">
                  <div className="row mx-0">
                    <div className="col-lg-6">
                      <Form.Item label="Model Name" className="my-1 py-1">
                        <Input name="model_name" value={this.state.inputValueModelName} onChange={this.changeInputValueModelName} placeholder="Model Name" disabled={this.state.formEdit} />
                      </Form.Item>
                      <Form.Item label="Market Name" className="my-1 py-1">
                        <Input name="market_name" value={this.state.inputValueMarketName} onChange={this.changeInputValueMarketName} placeholder="Market Name" />
                      </Form.Item>
                      <Form.Item label="Series Name" className="my-1 py-1">
                        <Input name="series_name" value={this.state.inputValueSeriesName} onChange={this.changeInputValueSeriesName} placeholder="Series Name" />
                      </Form.Item>
                      <SelectActive value={this.state.inputValueStatus} onChangeValue={this.changeInputValueStatus}></SelectActive>

                      <Form.Item label="Link Image" className="my-1 py-1">
                        <Input name="url_image" value={this.state.inputValueUrlImage} onChange={this.changeInputUrlImage} placeholder="Link Image" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-6">
                      <SelectBrand value={this.state.inputValueBrandUUID} onChangeValue={this.changeInputValueBrand} disabled={this.state.formEdit} />
                      <SelectCategory value={this.state.inputValueCategory} onChangeValue={this.changeInputCategory} disabled={this.state.formEdit} />
                      <SelectClass
                        value={this.state.inputValueClass}
                        onChangeValue={this.changeInputClass}
                        // disabled={this.state.formEdit}
                      />
                      <Form.Item label="Link Info" className="my-1 py-1">
                        <Input name="url_info" value={this.state.inputValueUrlInfo} onChange={this.changeInputUrlInfo} placeholder="Link Wiki" />
                      </Form.Item>
                      <Form.Item label="Link Image Thumbnail" className="my-1 py-1">
                        <Input name="url_image" value={this.state.inputValueUrlImageThumb} onChange={this.changeInputUrlImageThumb} placeholder="Link Image" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mx-0">
                    <div className="col-lg-12"></div>
                    <div className="col-lg-12 mx-0 my-2">
                      <div className="code-box-title  border-bottom border-secondary">
                        <strong>Feedback </strong>
                      </div>
                      <div className="row mx-0">
                        <div className="col-lg-6 px-0">
                          <Form.Item label="Author" className="my-1 py-1">
                            <Input name="feedback_author" value={this.state.inputFeedbackAuthor} onChange={this.changeInputFeedbackAuthor} placeholder="Feedback Auhor" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="col-lg-12 px-0">
                          <Button
                            // onClick={this.addColorList}
                            onClick={this.showFeedbackModal}
                            className="mt-2"
                            type="primary"
                            icon={<PlusSquareOutlined />}
                          >
                            Add Feedback
                          </Button>
                          <div className="row mx-0 mt-2 ">
                            <table id="color_list" className=" col-lg-12 px-1 table-color">
                              <tr>
                                <th className="text-center">Title</th>
                                <th className="text-center">Type Feedback</th>
                                <th className="text-center">Language</th>
                                <th className="text-center"></th>
                              </tr>
                              {this.state.feedback_list.map((r, index) => (
                                <tr id={index} key={index}>
                                  <td className="px-2 py-0">{r.title}</td>
                                  <td className="px-2 py-0">{r.type}</td>
                                  <td className="px-2 py-0">{r.language}</td>
                                  <td className="text-center">
                                    <Button onClick={() => this.deleteFeedbackList(r)} type="danger" icon={<DeleteOutlined />} className="px-2 me-1 my-1" />
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mx-0 mt-5 mb-1">
                      <div className="code-box-title  border-bottom border-secondary">
                        <strong>Other Display</strong>
                      </div>
                      <div className="row mx-0 px-0" style={{ minHeight: 120, width: "100%" }}>
                        <div className="col-lg-12 px-0">
                          <Button
                            // onClick={this.addColorList}
                            onClick={this.showDisplayModal}
                            className="mt-2"
                            type="primary"
                            icon={<PlusSquareOutlined />}
                          >
                            Add Display
                          </Button>
                          <div className="row mx-0 mt-2 ">
                            <table id="display_list" className=" col-lg-12 px-1 table-color">
                              <tr>
                                <th className="text-center">Type</th>
                                <th className="text-center">Category Display</th>
                                <th className="text-center">Link Display</th>
                                <th className="text-center">Link Display Thumb</th>
                                <th className="text-center">Caption Display</th>
                                <th className="text-center"></th>
                              </tr>
                              {this.state.display_list.map((r, index) => (
                                <tr id={index} key={index}>
                                  <td className="px-2 py-0">{r.type}</td>
                                  <td className="px-2 py-0">{r.category}</td>
                                  <td className="px-2 py-0">
                                    <a href={r.url_asset} target="_blank" rel="noreferrer">
                                      Open Link
                                    </a>
                                  </td>
                                  <td className="px-2 py-0">
                                    <a href={r.url_asset_thumbnail} target="_blank" rel="noreferrer">
                                      Open Link
                                    </a>
                                  </td>
                                  <td className="px-2 py-0">{r.caption}</td>
                                  <td className="text-center">
                                    <Button onClick={() => this.deleteDisplayList(r)} type="danger" icon={<DeleteOutlined />} className="px-2 me-1 my-1" />
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div style={{ clear: "both" }}>&nbsp;</div> */}
                </div>

                <div className="col-lg-6">
                  <div className="row mx-0">
                    <div className="col-lg-12">
                      <Form.Item className="my-1 py-1" label="Engine Capacity" labelCol={{ span: 6 }} wrapperCol={{ span: 24 }}>
                        <Input type="number" name="engine_capacity" value={this.state.inputValueEngineCapacity} onChange={this.changeInputValueEngineCapacity} placeholder="Engine Capacity" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-6">
                      <Form.Item className="my-1 py-1" wrapperCol={{ span: 24 }}>
                        <Input type="number" name="engine_capacity_minimum" value={this.state.inputValueEngineCapacityMinimum} onChange={this.changeInputValueEngineCapacityMinimum} placeholder="Minimum Engine Capacity" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-6">
                      <Form.Item className="my-1 py-1" wrapperCol={{ span: 24 }}>
                        <Input type="number" name="engine_capacity_maximum" value={this.state.inputValueEngineCapacityMaximum} onChange={this.changeInputValueEngineCapacityMaximum} placeholder="Maximum Engine Capacity" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mx-0">
                    <div className="col-lg-6">
                      <SelectFuel value={this.state.inputValueFuel} onChangeValue={this.changeInputValueFuel} mode="multiple" />
                      <SelectFuelSupply value={this.state.inputValueFuelSupplySystem} onChangeValue={this.changeInputValueFuelSupplySystem} mode="multiple" />
                      <Form.Item label="Doors" className="my-1 py-1">
                        <Input type="number" name="doors" value={this.state.inputValueDoors} onChange={this.changeInputValueDoors} placeholder="Total Doors" />
                      </Form.Item>
                      <SelectChairs value={this.state.inputValueChairs} onChangeValue={this.changeInputValueChairs} mode="multiple" />
                    </div>
                    <div className="col-lg-6">
                      <SelectTransmission value={this.state.inputValueTransmission} onChangeValue={this.changeInputValueTransmission} mode="multiple" />
                      <SelectSegment value={this.state.inputValueSegment} onChangeValue={this.changeInputValueSegment} />
                      <SelectDriveType value={this.state.inputValueDriveType} onChangeValue={this.changeInputValueDriveType}></SelectDriveType>
                    </div>
                  </div>
                  <div className="row mx-0">
                    <div className="col-lg-12">
                      <Form.Item className="my-1 py-1" label="Year Production" labelCol={{ span: 6 }} wrapperCol={{ span: 24 }}>
                        <Input type="number" name="year_production" value={this.state.inputValueYearProduction} onChange={this.changeInputValueYearProduction} placeholder="Year Production" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-6">
                      <Form.Item className="my-1 py-1" wrapperCol={{ span: 24 }}>
                        <Input type="number" name="year_first_production" value={this.state.inputValueYearProductionFirst} onChange={this.changeInputValueYearProductionFirst} placeholder="First Year Production" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-6">
                      <Form.Item className="my-1 py-1" wrapperCol={{ span: 24 }}>
                        <Input type="number" name="year_latest_production" value={this.state.inputValueYearProductionLastest} onChange={this.changeInputValueYearProductionLastest} placeholder="Lastest Year Production" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mx-0">
                    <div className="col-lg-12">
                      <EditorAbout value={this.state.inputValueAbout} onChange={this.changeInputValueAbout}></EditorAbout>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-0 mt-3">
                <div className="col-lg-6">
                  <div className="row mx-0">
                    <div className="col-lg-12">
                      <Button onClick={this.onClickSave} className="me-1" type="primary" loading={this.state.loadingButton}>
                        Save Model
                      </Button>
                      <Button onClick={this.onClickCancel} type="danger" loading={this.state.loadingButton}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <Modal title="Add Feedback" visible={this.state.feedback_modal} onOk={this.addFeedbackList} onCancel={this.closeFeedbackModal} width={1000}>
            <div className="row mx-0">
              <div className="col-lg-8">
                <Form layout="horizontal" labelCol={{ span: 6 }}>
                  <Form.Item label="Feedback Title" className="my-1 py-1">
                    <Input value={this.state.inputFeedbackTitle} onChange={this.changeInputFeedbackTitle} />
                  </Form.Item>
                  <SelectFeedbackType value={this.state.inputFeedbackType} onChange={this.changeInputFeedbackType} />
                  <SelectFeedbackLanguage value={this.state.inputFeedbackLanguage} onChange={this.changeInputFeedbackLanguage} />
                  <Form.Item label="Feedback Description" className="my-1 py-1">
                    <TextArea value={this.state.inputFeedbackDescription} onChange={this.changeInputFeedbackDescription}></TextArea>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Modal>

          <Modal title="Add Display" visible={this.state.display_modal} onOk={this.addDisplayList} onCancel={this.closeDisplayModal}>
            <div className="row mx-0">
              <div className="col-lg-12 ">
                <Form layout="vertical">
                  <SelectDisplayType value={this.state.inputDisplayType} onChangeValue={this.changeInputDisplayType} />
                  <SelectDisplayCategory value={this.state.inputDisplayCategory} onChangeValue={this.changeInputDisplayCategory} />
                  <Form.Item label="Link Display" className="my-1 py-1">
                    <Input name="link_display" value={this.state.inputDisplayLink} onChange={this.changeInputDisplayLink} placeholder="Link Image" />
                  </Form.Item>
                  <Form.Item label="Link Display Thumbnail" className="my-1 py-1">
                    <Input name="link_display_thumbnail" value={this.state.inputDisplayLinkThumb} onChange={this.changeInputDisplayLinkThumb} placeholder="Link Image" />
                  </Form.Item>
                  <Form.Item label="Caption Display" className="my-1 py-1">
                    <TextArea value={this.state.inputDisplayCaption} onChange={this.changeInputDisplayCaption}></TextArea>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Modal>

          <Modal title={`Distribute Model ${this.state.distModalLabel?.toUpperCase()}`} visible={this.state.distributeModal} onOk={this.onDistributeData} onCancel={this.closeDistributeModal} width={600}>
            <Form layout="vertical">
              <div className="row mx-0 px-0">
                <div className="row mx-0">
                  <div className="col-lg-6 mx-0 ps-0">
                    <SelectDistributionDestination value={this.state.distDestination} onChangeValue={this.changeDistDestination} />
                  </div>
                  <div className="col-lg-6 mx-0 ps-0">
                    <Form.Item label="Area" className="col-lg-12 my-1 py-1">
                      <Select style={{ fontSize: "85%" }} placeholder="Select an Area" onChange={this.changeDistArea} value={this.state.distArea}>
                        {this.state.dataOptionArea.map((d, i) => {
                          return (
                            <Select.Option key={d.area} value={d.area} add={d.area}>
                              {d.area}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-lg-6 mx-0 ps-0">
                    <Form.Item label="Price Default" className="my-1 py-0">
                      <Input type="number" value={this.state.distPriceDefault} onChange={(event) => this.handleFieldChange("distPriceDefault", event.target.value)} placeholder="Price Default" />
                    </Form.Item>
                  </div>
                  <div className="col-lg-6 mx-0 ps-0">
                    <Form.Item label="Price Strikethrough" className="my-1 py-0">
                      <Input type="number" value={this.state.distPriceStrikethrough} onChange={(event) => this.handleFieldChange("distPriceStrikethrough", event.target.value)} placeholder="Price Strikethrough" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-lg-6 mx-0 ps-0">
                    <Form.Item label="Price Min" className="my-1 py-0">
                      <Input type="number" value={this.state.distPriceMin} onChange={(event) => this.handleFieldChange("distPriceMin", event.target.value)} placeholder="Price Min" />
                    </Form.Item>
                  </div>
                  <div className="col-lg-6 mx-0 ps-0">
                    <Form.Item label="Price Max" className="my-1 py-0">
                      <Input type="number" value={this.state.distPriceMax} onChange={(event) => this.handleFieldChange("distPriceMax", event.target.value)} placeholder="Price Max" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-lg-12 mx-0 ps-0">
                    <Form.Item label="Image Url" className="my-1 py-0">
                      <Input value={this.state.distImageUrl} onChange={(event) => this.handleFieldChange("distImageUrl", event.target.value)} />
                    </Form.Item>
                  </div>
                  <div className="col-lg-12 mx-0 ps-0">
                    <Form.Item label="Image Url Thumbnail" className="my-1 py-0">
                      <Input value={this.state.distImageThumbUrl} onChange={(event) => this.handleFieldChange("distImageThumbUrl", event.target.value)} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </Modal>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default MasterVehicleModel;
